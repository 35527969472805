import React , {useState , useEffect} from 'react';
import "../styles/style.scss";

import { Link } from "gatsby";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

//HUBSPOT
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";


function Homepage() {
    const { loaded, error, formCreated } = useHubspotForm({
        region: "na1",
        portalId: "19573488",
        formId: "93f20bd7-1619-49cb-bd37-ba5f95844106",
        target: "#my-hubspot-form3",
    });

    const [tabChange , tabChangeFunction] = useState("RETAILER");
    const [tabDesc , setTabDesc] = useState("A retailer is an entity that sells items to customers for a profit. Retailers can upgrade with OiC premium features to.. enhance their sales by POS, CRM and Shophub.");
    const [moveLimit , changerLimit] = useState(0);
    const [cardCount , changeCardCount] = useState(1);
    const [totalCount , setTotalCount] = useState(0);
    const [responsiveLimit , setResponsiveLimit] = useState(1);
    const [navbarBg , setNavbarBg] = useState(false);
    
    //Carousel Movement
    useEffect(() => {
        let slide = document.getElementById("feedback-slide");
        // let noOfCards = document.getElementsByClassName("feedbackCard");
        // const initialLimit = 365;
        // const carousellimit = initialLimit * (noOfCards.length - 2);
        
        //Change
        slide.style.transform = `translateX(-${moveLimit}px)`;

    }, [moveLimit])

    //Carousel Limit
    useEffect(() => {
        let totalCardCount = document.getElementsByClassName("feedbackCard").length;
        if(cardCount <= responsiveLimit) changeCardCount(responsiveLimit);
        else if(cardCount > totalCardCount) changeCardCount(totalCardCount);
        console.log(cardCount)
    }, [cardCount])


    //SSR Fix For TotalCount due to document error
    useEffect(() => {
        AOS.init({
            once: true
        });

        let totalCounts= document.getElementsByClassName("feedbackCard").length;
        setTotalCount(totalCounts);
    }, [])

    const changeNavbarBackground = () => {
        if(window.scrollY > 0){
            setNavbarBg(true);
            // console.log(window.scrollY)
        }
        else{
            setNavbarBg(false);
        }
    }

    useEffect(() => {

        if(AOS){
            AOS.refresh();
        }

        window.addEventListener("scroll", changeNavbarBackground);
    
        //Set Responsive Limit
        if(window.innerWidth < 768 && responsiveLimit !== 1){
            setResponsiveLimit(1);
            changeCardCount(1);
        }
        else if(window.innerWidth >= 768 && responsiveLimit !== 3){
            setResponsiveLimit(3);
            changeCardCount(3);
        }

    })

    useEffect(() => {
        if(tabChange === "WHOLESALER"){
            setTabDesc("A distributor is the sole source for retailers to get that product throughout the area.");
        } else if(tabChange == "RETAILER") {
            setTabDesc("A retailer is an entity that sells items to customers for a profit. Retailers can upgrade with OiC premium features to.. enhance their sales by POS, CRM and Shophub.");
        } else{
            setTabDesc("It's a fact that everyone is incredibly brand aware today. In the case of eyewear, this brand consciousness is... also becoming popular");
        }
    },[tabChange])

    return (
        <div>
            
            {/* HomePage Banner */}
            
            <div className="homepageBanner">

                <div className={navbarBg ? "homepage homepageScrollBg" : "homepage"}>
                    
                    <div className="homepageNavbar">
                        <img height="75" src={require("../img/favicon.png")} alt="oic-logo-black" />

                        <ul>
                            <li>HOME</li>
                            <li>PRODUCTS</li>
                            <li>ABOUT US</li>
                            <li>BLOG</li>
                            <li>REACH US</li>
                        </ul>

                    </div>
                    
                </div>

                <div className="homepageContext">
                    <h1>Technology For Your Better<br /><span style={{color: "#157a9a"}}>Business Vision</span> </h1>
                    {/* <p>Get ready for the next era of commerce.</p>
                    <div>
                        <button className="btn-1">Start Free Demo</button>
                        <img alt="start-here" src={require("../img/hp/play.svg")} height="90" />
                    </div> */}
                    <div id="my-hubspot-form3"></div>
                </div>


            </div>


            {/* Wholesaler, Retailer, Brands */}
            
            <div className="tabContainer">
                
                <div className="tabPoster" data-aos="fade-right" data-aos-duration="1500"> 

                    <ul>
                        <li onClick={() => tabChangeFunction("WHOLESALER")}
                            className={tabChange === "WHOLESALER" && "present"}>Wholesaler
                        </li>
                        <li onClick={() => tabChangeFunction("RETAILER")}
                            className={tabChange === "RETAILER" && "present"}>Retailer
                        </li>
                        <li onClick={() => tabChangeFunction("BRANDS")}
                            className={tabChange === "BRANDS" && "present"}>Brands
                        </li>
                    </ul>

                    <div className="tabDetails">

                        <div><img alt="front-view-woman-with-sunglasses" src={require("../img/hp/front-view-woman-with-sunglasses.jpg")} /></div>
                        
                        <div className="tabText">

                            <h2>Build Your Successful<br />Buisness With Us</h2>

                            <p>{tabDesc}
                            </p>

                            <button className="btn-1">Learn More</button>

                        </div>
                    </div>

                </div>
                
            </div>

            {/* APPS */}

            <div className="appsContainer">

                <div data-aos="fade-left" data-aos-duration="1500">
            
                    <div className="appsTitle">

                        <h1>More than just a tool</h1>
                        <p>Explore what else we can do for you</p>

                    </div>

                    <div className="appsCardSpace">

                        {/* 1 */}

                        <div className="appsCard">

                            <div className="appsThumbnail"><img alt="fakurian-design" src={require("../img/hp/marketplace/fakurian-design.jpg")} /></div>

                            <div className="appsInfo">
                                <img style={{padding: "3% 0%"}} alt="marketplace" src={require("../img/hp/marketplace/marketplace.svg")} width="45" />
                                <p>Market Place</p>
                            </div>

                            <div className="appsDesc">
                                <p>The platform embarks on and uploads its stock data to 
                                    the inventory of the brands or distributors.
                                </p>
                            </div>

                            <div className="appsPrice">
                                <div className="appsPriceBox">
                                    <Link to="/market-place/"><p>Read More</p></Link>
                                    {/* <p>$20<span>/Month</span></p> */}
                                </div>

                                <Link to="/market-place/"><div className="appsNext"></div></Link>
                            </div>

                        </div>

                        {/* 2 */}

                        <div className="appsCard">

                            <div className="appsThumbnail"><img alt="portrait-man-face" src={require("../img/hp/3dtryon/portrait-man-face.jpg")} /></div>

                            <div className="appsInfo">
                                <img alt="3dtryon" src={require("../img/hp/3dtryon/3dtryon.jpg")} width="60" />
                                <p>Live 3D Try On</p>
                            </div>

                            <div className="appsDesc">
                                <p>Our platform captures a precise map of each customer's face 
                                    and their personal STYLE preferences to help them
                                </p>
                            </div>

                            <div className="appsPrice">
                                <div className="appsPriceBox">
                                    <Link to="/virtual-mirror/"><p>Read More</p></Link>
                                    {/* <p>$17<span>/Month</span></p> */}
                                </div>

                                <Link to="/virtual-mirror/"><div className="appsNext"></div></Link>
                            </div>

                        </div>

                        {/* 3 */}

                        <div className="appsCard">

                            <div className="appsThumbnail"><img alt="website-builder" src={require("../img/hp/website-builder/website-builder.jpg")} /></div>

                            <div className="appsInfo">
                                <img alt="2dtryon" src={require("../img/hp/website-builder/2dtryon.jpg")} width="60" />
                                <p>Website Builder</p>
                            </div>

                            <div className="appsDesc">
                                <p>Create your unique website effortlessly to impress and engage 
                                    customers. Building your website with a website builder.
                                </p>
                            </div>

                            <div className="appsPrice">
                                <div className="appsPriceBox">
                                    <Link to="/website-building/"><p>Read More</p></Link>
                                    {/* <p>$22<span>/Month</span></p> */}
                                </div>

                                <Link to="/website-building/"><div className="appsNext"></div></Link>
                            </div>

                        </div>

                        {/* 4 */}

                        <div className="appsCard">

                            <div className="appsThumbnail"><img alt="contactlens" src={require("../img/hp/lensimulation/contactlens.jpg")} /></div>

                            <div className="appsInfo">
                                <img alt="lensimulation" src={require("../img/hp/lensimulation/lens.jpg")} width="60" />
                                <p>Lens Simulation</p>
                            </div>

                            <div className="appsDesc">
                                <p>Makes trying on frames about the lenses as well. 
                                    With OiC Lens Simulator, customers are able to visualize 
                                    the various lens effects.
                                </p>
                            </div>

                            <div className="appsPrice">
                                <div className="appsPriceBox">
                                    <Link to="/lens-simulation/"><p>Read More</p></Link>
                                    {/* <p>$25<span>/Month</span></p> */}
                                </div>

                                <Link to="/lens-simulation/"><div className="appsNext"></div></Link>
                            </div>

                        </div>

                        {/* 5 */}

                        <div className="appsCard">

                            <div className="appsThumbnail"><img alt="clay-banks" src={require("../img/hp/crm/clay-banks.jpg")} /></div>

                            <div className="appsInfo">
                                <img style={{padding: "3% 0%"}} alt="crm" src={require("../img/hp/crm/crm.svg")} width="45" />
                                <p>CRM</p>
                            </div>

                            <div className="appsDesc">
                                <p>Retain customers with CRM that helps you recall customers for 
                                    next eye-exam, lens replenishment, new eye-frame arrival etc. 
                                </p>
                            </div>

                            <div className="appsPrice">
                                <div className="appsPriceBox">
                                    <Link to="/crm/"><p>Read More</p></Link>
                                    {/* <p>$13<span>/Month</span></p> */}
                                </div>

                                <Link to="/crm/"><div className="appsNext"></div></Link>
                            </div>

                        </div>

                        {/* 6 */}

                        <div className="appsCard">

                            <div className="appsThumbnail"><img alt="invoice" src={require("../img/hp/pos/invoice.jpg")} /></div>

                            <div className="appsInfo">
                                <img style={{padding: "3% 0%"}} alt="pos" src={require("../img/hp/pos/pos.svg")} width="45" />
                                <p>POS</p>
                            </div>

                            <div className="appsDesc">
                                <p>POS empowers retailers with a user-friendly interface that 
                                    aims to deliver seamless retail experiences. 
                                </p>
                            </div>

                            <div className="appsPrice">
                                <div className="appsPriceBox">
                                    <Link to="/pos/"><p>Read More</p></Link>
                                    {/* <p>$22<span>/Month</span></p> */}
                                </div>

                                <Link to="/pos/"><div className="appsNext"></div></Link>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/* CONNECT US */}

            <div className="connectUsContainer">

                <div className="connectUs" data-aos="fade-right" data-aos-duration="1500">

                    <div><img alt="connectwithus" src={require("../img/hp/connectwithus.svg")} /></div>
                    
                    <div className="connectUsBg">

                        <h1>Why OIC?</h1>

                        <p>The Reason For Choosing Oic Is That We Provide B2B And B2C, 
                            Our Oic Platform Allows Brand/Distributor To Digitally 
                            Communicate With Each Other, But It Will Not End Just There, 
                            Supporting Distributors To Connect With Consumers And Making 
                            Shopping Simple And Reliable.
                        </p>

                        <div><button type="button">Connect With Us</button></div>

                    </div>

                </div>

            </div>

            {/* BLOGS */}

            <div className="blogsContainer">

                <div data-aos="fade-left" data-aos-duration="1500">

                    <div className="blogsHeader">

                        <h1>Our BLOG</h1>

                        <p>Our Latest Updates Are Available Here</p>

                    </div>

                    <div className="blogsCardSpace">
                        
                        {/* 1 */}

                        <div className="blogsCard">

                            <div className="blogsThumbnail"><img alt="beautiful-stylish-woman-hat-sunglasses" src={require("../img/hp/blog/beautiful-stylish-woman-hat-sunglasses.jpg")} /></div>
                            
                            <div className="blogsFame">
                                <div className="blogsFameBox">
                                    <p>Popular</p>
                                </div>

                                <div className="blogsFameSpecified"><p>Today</p></div>
                            </div>

                            <div className="blogsTrendDesc">
                                
                                <h3>Eyewear Trends In 2021</h3>

                                <p>The biggest eyewear trends in 2021</p>

                            </div>

                            <div className="blogsLink"><Link to="/blog/the-biggest-eyewear-trends-of-2021/">Read More</Link></div>

                        </div>

                        {/* 2 */}

                        <div className="blogsCard">

                            <div className="blogsThumbnail"><img alt="young-asia-girl-wearing-medical-face-mask-thumb-up" src={require("../img/hp/blog/young-asia-girl-wearing-medical-face-mask-thumb-up.jpg")} /></div>
                            
                            <div className="blogsFame">
                                <div className="blogsFameBox">
                                    <p>Popular</p>
                                </div>

                                <div className="blogsFameSpecified"><p>Today</p></div>
                            </div>

                            <div className="blogsTrendDesc">
                                
                                <h3>Impact of COVID</h3>

                                <p>Impact of COVID on eyewear industry</p>

                            </div>

                            <div className="blogsLink"><Link to="/blog/impact-of-covid-on-eyewear-industry">Read More</Link></div>

                        </div>

                        {/* 3 */}

                        <div className="blogsCard">

                            <div className="blogsThumbnail"><img alt="thoughtful-lady-with-eyeglasses" src={require("../img/hp/blog/thoughtful-lady-with-eyeglasses.jpg")} /></div>
                            
                            <div className="blogsFame">
                                <div className="blogsFameBox">
                                    <p>Popular</p>
                                </div>

                                <div className="blogsFameSpecified"><p>Today</p></div>
                            </div>

                            <div className="blogsTrendDesc">
                                
                                <h3>Virtual Mirror Technology</h3>

                                <p>Virtual Mirror Technology - It will change the way you shop</p>

                            </div>

                            <div className="blogsLink"><Link to="/virtual-mirror/">Read More</Link></div>

                        </div>

                    </div>

                </div>

            </div>

            {/* FEEDBACKS */}

            <div className="feedbackContainer">

                <div data-aos="fade-right" data-aos-duration="1500">

                    <div className="feedbackHeader">

                        <h1>Client Feedback</h1>

                        <div>
                            <button disabled={cardCount === responsiveLimit ? true : false} onClick={() => {
                                    if(cardCount !== responsiveLimit)
                                        changerLimit(moveLimit-365);
                                        changeCardCount(cardCount-1);
                                    }
                                }><img width="35" alt="prev" id="carousel-previous" src={require("../img/hp/cprevwhite.png")} />
                            </button>

                            <button disabled={cardCount === totalCount ? true : false} onClick={() => {
                                    if(cardCount !== totalCount)
                                        changerLimit(moveLimit+365);
                                        changeCardCount(cardCount+1);
                                    }
                                }><img width="50" alt="next" id="carousel-next" src={require("../img/hp/carouselnext.svg")} />
                            </button>
                        </div>

                    </div>
                    
                    <div className="feedbackCarouselContainer">

                        <div className="feedbackshowcase">

                            <div className="feedbackCardSpace" id="feedback-slide" data-aos="fade-right">

                                {/* 1 */}

                                <div className="feedbackCard">
                                    
                                    <div className="feedbackCardSpecial">
                                        <img alt="double-quote" src={require("../img/hp/specialdoublequote.svg")} width="30" />
                                    </div>

                                    <div className="feedbackCardDetails">
                                        <p>I Loved the whole platform.As a brand it helped us 
                                            reach to maximum retailer and also help us organise 
                                            and expand our base
                                        </p>
                                    </div>

                                    <div className="feedbackCardSender">
                                        <div><img alt="profile-1" width="40" src={require("../img/hp/feedback/profile-1.jpg")} /></div>

                                        <div className="feedbackCardSenderProfile">
                                            <h4>Benjamin Fox</h4>
                                            <p>Optical World Manager</p>
                                        </div>
                                    </div>

                                </div>

                                {/* 2 */}

                                <div className="feedbackCard">
                                    
                                    <div className="feedbackCardSpecial">
                                        <img alt="double-quote" src={require("../img/hp/specialdoublequote.svg")} width="30" />
                                    </div>

                                    <div className="feedbackCardDetails">
                                        <p>Being an optical distributor, enjoying the OiC platform that 
                                            helps to reach the right retailer and highly recommended.
                                        </p>
                                    </div>

                                    <div className="feedbackCardSender">
                                        <div><img alt="profile-2" width="40" src={require("../img/hp/feedback/profile-2.jpg")} /></div>

                                        <div className="feedbackCardSenderProfile">
                                            <h4>Joan Ford</h4>
                                            <p>Eye Care Associate</p>
                                        </div>
                                    </div>

                                </div>

                                {/* 3 */}

                                <div className="feedbackCard">
                                    
                                    <div className="feedbackCardSpecial">
                                        <img alt="double-quote" src={require("../img/hp/specialdoublequote.svg")} width="30" />
                                    </div>

                                    <div className="feedbackCardDetails">
                                        <p>I Loved the whole platform.As a brand it helped us reach to 
                                            maximum retailer and also help us organise and expand our 
                                            base
                                        </p>
                                    </div>

                                    <div className="feedbackCardSender">
                                        <div><img alt="profile-3" width="40" src={require("../img/hp/feedback/profile-3.jpg")} /></div>

                                        <div className="feedbackCardSenderProfile">
                                            <h4>Steve Reyes</h4>
                                            <p>Galaxy Opticals Owner</p>
                                        </div>
                                    </div>

                                </div>

                                {/* 4 */}

                                <div className="feedbackCard">
                                    
                                    <div className="feedbackCardSpecial">
                                        <img alt="double-quote" src={require("../img/hp/specialdoublequote.svg")} width="30" />
                                    </div>

                                    <div className="feedbackCardDetails">
                                        <p>Being an optical distributor, enjoying the OiC platform that 
                                            helps to reach the right retailer and highly recommended.
                                        </p>
                                    </div>

                                    <div className="feedbackCardSender">
                                        <div><img alt="profile-4" width="40" src={require("../img/hp/feedback/profile-1.jpg")} /></div>

                                        <div className="feedbackCardSenderProfile">
                                            <h4>Benjamin Fox</h4>
                                            <p>Optical World Manager</p>
                                        </div>
                                    </div>

                                </div>

                                {/* 5 */}

                                <div className="feedbackCard">
                                    
                                    <div className="feedbackCardSpecial">
                                        <img alt="double-quote" src={require("../img/hp/specialdoublequote.svg")} width="30" />
                                    </div>

                                    <div className="feedbackCardDetails">
                                        <p>I Loved the whole platform.As a brand it helped us reach to 
                                            maximum retailer and also help us organise and expand our 
                                            base
                                        </p>
                                    </div>

                                    <div className="feedbackCardSender">
                                        <div><img alt="profile-5" width="40" src={require("../img/hp/feedback/profile-2.jpg")} /></div>

                                        <div className="feedbackCardSenderProfile">
                                            <h4>Joan Ford</h4>
                                            <p>Eye Care Associate</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        
                        </div>

                    </div>
                
                </div>
                
            </div>

            {/* CLIENTS */}

            <div className="clientsContainer">

                <div data-aos="fade-left" data-aos-duration="1500">

                    <div className="clientsHeader">
                        
                        <h1>Our Clients</h1>

                        <p>Explore what else we can do for you</p>

                    </div>

                    <div className="clientLogoShowcase">

                        <div><img alt="eyewear-logo" width="60" src={require("../img/hp/logos/eyewear-logo.svg")} /></div>
                        <div><img alt="tox-logo" width="80" src={require("../img/hp/logos/tog-logo.svg")} /></div>
                        <div><img alt="xfibre-logo" width="100" src={require("../img/hp/logos/xfibre-logo.svg")} /></div>
                        <div><img alt="vision-logo" width="60" src={require("../img/hp/logos/vision-logo.svg")} /></div>
                        <div><img alt="eyewear-logo" width="60" src={require("../img/hp/logos/eyewear-logo.svg")} /></div>
                        <div><img alt="tox-logo" width="80" src={require("../img/hp/logos/tog-logo.svg")} /></div>

                    </div>
                </div>
            </div>

            {/* CONNECT BY MAIL NOTIFICATIONS */}

            <div className="connectMailContainer">

                <div className="connectMailBg" data-aos="fade-right" data-aos-duration="1500">

                    <img alt="a-man-with-blue-bg" src={require("../img/hp/a-man-with-blue-bg.jpg")} />
                    
                    <div className="connectMailContent">

                        <p>CONNECT WITH US</p>

                        <h2>Its Time To Change<br /><span>Your Life Today</span></h2>

                        <div className="connectMailButton">

                            <div>
                                
                                <div>

                                    <img alt="email" src={require("../img/hp/email.svg")} width="20" />
                                
                                    <input placeholder="Your Mail Address" />

                                </div>
                                

                                <button type="button">Connect</button>

                            </div>
                            
                        </div>

                    </div>

                </div>

            </div>

            {/* Homepage Footer */}

            <div className="hpfooterContainer">

                <div className="hpfooter">
                    
                    {/* col-1 */}

                    <div className="hpfooterStoreDetails">

                        <img alt="oic-logo" width="60" src={require("../img/hp/oic-logo.svg")} />

                        <p>OiC is an online marketplace platform primarily <br />
                            built for the optical sector to ease the challenge <br />
                            of distributors/retailers under a single roof to <br />
                            digitally increase their efficiency
                        </p>

                        <button type="button">Contact Us</button>

                    </div>

                    {/* col-2 */}

                    <div className="hpfooterStoreTabs">

                        <ul>

                            <li>About Us</li>

                            <li>Products</li>

                            <li>Blogs</li>

                            <li>Privacy</li>

                        </ul>

                    </div>

                    {/* col-3 */}

                    <div className="hpfooterStoreTabs">

                        <ul>
                            
                            <li>Terms & Conditions</li>

                            <li>FAQ</li>

                            <li>Sitemap</li>

                            <li>Media</li>

                        </ul>

                    </div>

                    {/* col-4 */}

                    <div className="hpfooterStoreReachUs">
                        
                        <div className="hpfooterStoreAddress">

                            <p className="hpfooterSpecialHeading">Address</p>

                            <p>Mount Road, 244, <br />
                                Anna Salai, Thousand Lights, <br />
                                Chennai, Tamil Nadu 600006
                            </p>

                        </div>
                        
                        <div className="hpfooterStoreContact">

                            <p className="hpfooterSpecialHeading">Contact</p>

                            <div>

                                <img alt="call" width="15" src={require("../img/hp/call.svg")} />

                                <p>+91 97918 36596</p>

                            </div>

                            <div>

                                <img alt="mail" width="18" src={require("../img/hp/mail.svg")} />

                                <p>Marketing@oicapps.com</p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            
            {/* CONTACT PLATFORMS */}

            <div className="contactPlatforms">   

                <img alt="facebook" width="20" src={require("../img/hp/socialmedia/facebook.svg")} />

                <img alt="instagram" width="20" src={require("../img/hp/socialmedia/instagram.svg")} />

                <img alt="linkedin" width="20" src={require("../img/hp/socialmedia/linkedin.svg")} />
                
                <img alt="twitter" width="20" src={require("../img/hp/socialmedia/twitter.svg")} />

            </div>

        </div>
    )
}

export default Homepage
